import React from 'react';

import { UqArticleQuote, UqCustomerStory, UqCustomerProfile, UqRequestDemoBanner, UqTypography, UqLink, UqContainerV2, UqAppBarV2, UqFooterV2, UqNavbarV2, UqLayoutV2, UqPage } from '@uq-components';
import Logo from '@uq-assets/logos/udemy.svg';

import * as styles from './udemy.module.scss';

export default function UdemyPage() {
  return (
    <UqPage
      config={{
        seo: {
          appendHostUrl: true,
          description: `unitQ provides Udemy actionable insights to transform student,
          instructor feedback into exceptional user experiences`,
          src: '/images/v2/customers/udemy-hero.png',
          title: 'Udemy Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header
            backgroundSrc="/images/v2/customers/udemy-hero.png"
            classes={{ background: styles.hero }}
          >
            <UqTypography as="h1">
              unitQ provides Udemy actionable insights to transform student, instructor feedback into exceptional user experiences
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">Automotive Research & Shopping</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">Cambridge, Massachusetts</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">1,200+</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">
                It was difficult to decipher insights quickly from customer
                user-feedback data because it was fragmented and difficult to parse.
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Conclusion">
                unitQ enables a holistic view of user feedback data,
                helping CarGurus refine their product offerings and better meet the needs of customers.
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>
              <p>
                <UqLink
                  className="article-link"
                  to="https://www.udemy.com/"
                  external
                  hideArrow
                >
                  Udemy
                </UqLink>
                <span>
                  , founded in 2010, is a leading online course provider aimed at professional adults and students.
                  The platform has more than 52 million students, 196,000 courses, and 68,000 instructors teaching in over 75 languages.
                  There have been over 712 million course enrollments.
                </span>
              </p>
              <p>
                Students take online courses from the San Francisco-based company primarily to improve job-related skills.
                Some courses generate credit toward technical certification.
                Udemy has also taken special efforts to attract corporate trainers seeking to create coursework for employees.
              </p>

              <UqTypography as="h4">
                The problem: !-A deluge of customer feedback overwhelmed developers-!
              </UqTypography>

              <p>
                Customers have the deepest insights about an organization&apos;s product.
                When it comes to Udemy, those customers are millions of students and thousands of instructors.
              </p>
              <p>
                With so much user feedback, about 77,000 pieces per month,
                it was becoming a sisyphean labor for Udemy to manually identify,
                tag and quantify feedback across a wide swath of channels —
                including from sources like Zendesk support tickets, Khoros,
                the iOS and Android app stores, Pissed Consumer, Reddit, TrustPilot, Twitter and more.
              </p>
              <p>
                Udemy, the global destination for online learning, understood that customers are the best resource to understand what is,
                and what is not working for their products and services.
                But Udemy&apos;s legacy solution was not adequately bucketing and providing the necessary
                actionable insights from user feedback that was streaming in from dozens of languages and sources.
                Hence, it was difficult for developers to track and fix bugs,
                and to plan future product roadmaps to enhance the overall customer experience.
              </p>
              <p>
                In response, Udemy went searching for a tool with detailed accounting of issues identified by their customers,
                as well as a tool to gauge customer sentiment.
              </p>

              <UqTypography as="h4">
                The solution: !-unitQ provides real-time actionable insights from user feedback-!
              </UqTypography>

              <p>
                unitQ’s AI-enabled Voice-of-the-Customer platform was able to capture valuable feedback from their customers.
                This included bread-and-butter issues like videos failing to load or play, missing accounts and classes,
                and the inability to login for both students and instructors —
                issues that typically arise from a mammoth online learning and teaching service such as Udemy.
                Other more hidden issues, like the wrong video loading, are also being surfaced by unitQ.
              </p>
              <p>
                With some added tagging on top of product quality issues that unitQ
                aggregates in real time for developers and support staff,
                Udemy was able to implement a skills-based routing system that would notify
                pre-selected developers that they need to jump on getting certain issues fixed.
              </p>
              <p>
                Udemy uses unitQ to classify all of their feedback, especially Zendesk tickets,
                and then pushes that data as tags back into Zendesk to power their skill-based routing.
                Udemy uses unitQ dashboards to evaluate how their feedback is
                trending across the diverse landscape of their product and organization.
              </p>
              <p>
                For example, Udemy can visualize the top issues affecting different segments of customers
                (instructors vs students vs enterprises) to understand what are the most important issues that are impacting each segment,
                and across all segments.
              </p>
              <p>
                In addition, with specialized tagging, Udemy tracks the sentiment around promotional code releases,
                discount codes and coupons to see how they are performing.
              </p>

              <UqArticleQuote
                author={{
                  name: 'Erin Sink',
                  role: 'Vice President, Global Customer Operations',
                }}
              >
                We dropped our legacy user-feedback solution and now rely on unitQ to surface actionable insights
                about what our students and instructors are saying about our products and services in real time.
                unitQ essentially does all of the heavy lifting like data mining,
                enabling our support and developer teams to focus on getting issues fixed and plotting product roadmaps.
                With unitQ, we no longer have to spend time manually trying to surface issues affecting the customer experience.
              </UqArticleQuote>

              <p>
                The Udemy team also takes advantage of unitQ alerts, which show up in dedicated Slack channels.
                They have set up dozens of alerts for slow site performance,
                server errors, enrollment issues, refunds and you name it.
              </p>

              <br />

              <p>
                Udemy has also configured hundreds of saved searches that enable the team, with a click of a button,
                to view all the issues associated with whatever feedback bucket they have chosen —
                such as issues about enrollment problems, payment hiccups, refunds, negative sentiment and even “love” from customers.
                The team also visualizes student support ticket user feedback with the unitQ {' '}
                <UqLink
                  className="article-link"
                  to="/blog/visualize-user-feedback-with-unitq-charts-dashboards/"
                  external
                  hideArrow
                >
                  Charts & Dashboards feature
                </UqLink>.
              </p>
              <p>
                While unitQ often delivers bad news, the customer
                <UqLink
                  className="article-link"
                  to="/blog/unleash-the-power-of-customer-love-to-motivate-your-organization"
                  external
                  hideArrow
                >
                  {' '}“love”{' '}
                </UqLink>
                feature highlights positive feedback from customers.
                Clicking on the “Customer Love Keywords” in unitQ takes you to all the “love” feedback associated with that keyword.
                This keeps developers and support staff more engaged, and lets them know they are providing a valuable service.
              </p>

              <UqTypography as="h4">
                The results: !-Enhanced Udemy customer experience-!
              </UqTypography>

              <p>
                The overall user experience at Udemy has been strengthened —
                from signing up for an account to watching videos, making payments,
                and even to assigning instructors to teach classes.
                All in all, the site&apos;s performance is helping Udemy remain the
                leading player in the Massive Open Online Course (MOOC) space.
              </p>
              <p>
                What&apos;s more, support and developer personnel are spending more time strengthening the product,
                which has also increased their employment satisfaction, too. <br />
              </p>

              <p>
                <strong>
                  Positive outcomes with unitQ at a glance:
                </strong>
                <ul>
                  <li><span>Boosted app store ratings.</span></li>
                  <li><span>Increased customer base.</span></li>
                  <li><span>Reduced churn.</span></li>
                  <li><span>Enhanced developer and support staff job satisfaction.</span></li>
                  <li><span>Improved site performance.</span></li>
                  <li><span>Lowered number of support tickets.</span></li>
                  <li><span>Added new site features.</span></li>
                </ul>
              </p>

              <UqArticleQuote
                author={{
                  name: 'Erin Sink',
                  role: 'Vice President, Global Customer Operations',
                }}
              >
                Being a good technology leader means knowing what data should inform your decision making,
                and how to use this information for your company&apos;s advantage.
                This is why we brought unitQ into our tech stack, to empower Udemy with user-feedback data to detect,
                investigate, prioritize and fix issues near and dear to our customers.
                UnitQ allowed us to shine a light on issues in a more nuanced way that brought our category and
                issue tagging from dual to tertiary level – meaning we are able to delve deeper into root causes and sentiment.
              </UqArticleQuote>

            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />

      </UqLayoutV2>
    </UqPage>
  );
}
